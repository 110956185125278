export const environment = {
  production: false,
  apiUrl: 'https://apitestbc.azurewebsites.net',
  thisUrl: 'https://testbusinesscenter.azurewebsites.net',
  authenticationServiceUrl: 'https://test-ls-auth-service.azurewebsites.net',
  version: '00.11',

  saltpay: {
    paymentApiUrl: 'https://test.borgun.is/SecurePay/default.aspx',
    secureKey: 'cdedfbb6ecab4a4994ac880144dd92dc',
    merchantid: '9256684',
    paymentgatewayid: '471',
    merchantemail: 'yasir.butt@lsretail.com',
  },
};
