import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./pages/shell/shell.module').then((m) => m.ShellModule),
  },
  {
    path: 'shell',
    loadChildren: () => import('./pages/shell/shell.module').then((m) => m.ShellModule),
  },
  {
    path: 'unauthorized',
    loadChildren: () => import('./pages/shell/shell.module').then((m) => m.ShellModule),
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/auth/login/login.module').then((m) => m.LoginModule),
  },
  {
    path: 'select-partner',
    loadChildren: () => import('./pages/auth/select-partner/select-partner.module').then((m) => m.SelectPartnerModule),
  },
  {
    path: 'request-access',
    loadChildren: () => import('./pages/auth/access-denied/access-denied.module').then((m) => m.AccessDeniedModule),
  },
  {
    path: 'auth-callback',
    loadChildren: () => import('./pages/auth/auth-callback/auth-callback.module').then((m) => m.AuthCallbackModule),
  },
  {
    path: 'logout',
    loadChildren: () =>
      import('./pages/auth/logout-loaderpage/logout-loaderpage.module').then((m) => m.LogoutLoaderModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [],
})
export class AppRoutingModule {}
